






















export default {
	props: {
		choices: { required: true },
		selected: { required: false, type: Array }
	},

	computed: {
		isStatus: function() {
			return this.selected != undefined
		}
	},

	methods: {
		toggle(choice: any, i: number) {
			this.$emit('toggle', { choice, i })
		},

		statusClasses: function(choice) {
			return {
				'selected': choice._selected || (this.wasAnswerSelected(choice.id) && choice.is_correct),
				'correctUnselected': this.isStatus ? !this.wasAnswerSelected(choice.id) && choice.is_correct : false,
				'wrong': this.wasAnswerSelected(choice.id) && !choice.is_correct
			}
		},

		wasAnswerSelected(id) {
			if (!this.isStatus) {
				return false
			}

			return !!this.selected.find((choice) => choice.choice.id == id)
		}
	}
}
